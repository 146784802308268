/* html {
  --primaryBackground: #373c49;
  --secondaryBackground: rgb(81, 86, 99);
  --primaryTextColor: #ffffff;
  --primaryColor: #e6c569;
} */

html {
  --primaryBackground: #ffffff;
  --secondaryBackground: rgb(242, 242, 242);
  --primaryTextColor: #000000;
  --primaryColor: #be1b18;
}

html[data-theme='dark'] {
  --primaryBackground: #121212;
  --secondaryBackground: #1d1d1d;
  --primaryTextColor: #f2f2f2;
  --primaryColor: #eac644;
}

html.theme-transition,
html.theme-transition *,
html.theme-transition *:before,
html.theme-transition *:after {
  transition: all 750ms !important;
  transition-delay: 0 !important;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  background: var(--primaryBackground);
  padding: 0;
  height: 100vh;
}
#App {
  height: 100%;
  background: #f0f0f0;
  min-height: 100vh;
  overflow: auto;
}
#content-container {
  margin: auto;
  max-width: 1200px;
}
#content {
  padding: 10px;
  background: var(--primaryBackground);

  margin: 10px 10px;
  border-radius: 4px;
}
.section-title {
  margin: 8px 0 3px 15px;
}
.section-description {
  margin: -10px 0 5px 15px;
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  main#antd-content {
    background: #fff;
  }
}
.new-site {
  border-top: 2px solid #3ea08e;
  background: #1c3538;
  color: #fff;
}
.ant-notification-notice-message {
  color: #fff;
}
