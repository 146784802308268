.printShow {
  display: none !important;
}
.printHeader {
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-weight: bold;
  font-size: 26px;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.print-logo {
  height: 100%;
  max-height: 30px;
}
.print-info-container {
  display: flex;
  flex-direction: column;
  text-align: right;
}
.printSubHeader {
  font-weight: normal;
  font-size: 20px;
}
.printDateHeader {
  font-weight: normal;
  font-size: 15px;
  font-style: italic;
}
@media print {
  .printShow {
    display: grid !important;
  }
}
