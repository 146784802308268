.subHeader {
  font-size: 11px;
}
.ant-btn {
  height: 38px !important;
}
.ant-table-placeholder {
  z-index: 0 !important;
}
.ant-table {
  color: rgb(29, 29, 29) !important;
}
.ant-calendar-picker {
  width: 100%;
}
.ant-input {
  min-height: 38px;
}
.ant-calendar-input-wrap {
  height: 38px !important;
  padding-top: 9px;
}
.ant-select-selection--single {
  height: 38px !important;
  padding-top: 4px;
}
@media only screen and (max-width: 1000px) {
  .ant-table-body {
    font-size: 11px;
  }
  .tbl-title {
    font-size: 11px;
  }
}
