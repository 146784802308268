#nav {
  font-weight: 600;
  background: #001529;
  min-height: 60px;
  align-items: center;
  display: flex;
  color: #fff;
}
#company-logo-container {
  padding: 10px;
  margin: 0 20px;
}

#company-logo {
  max-height: 25px;
  max-width: 100%;
}
.nav-option {
  /* margin: 0 15px; */
  padding: 0 20px;
  font-size: 14px;
  height: 60px;
  display: flex;
  align-items: center;
  /* background: red; */
  color: #fff;
}
.nav-option a:hover {
  color: #fff !important;
}
.nav-option a {
  color: #fff;
}
.nav-option-active {
  background: #1890ff !important;
  color: #e0e0e0 !important;
}
